import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"

const Footer = () => {
  const {
    strapiSiteMetaData: {
      data: { attributes: strapiSiteData },
    },
  } = useStaticQuery(graphql`
    query FooterSiteDataQuery {
      strapiSiteMetaData {
        data {
          attributes {
            logo {
              data {
                attributes {
                  localFile {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <footer className="footer has-background-dark">
      <div className="container">
        <div className="level is-mobile">
          <Link className="navbar-item" to="/">
            <img
              src={strapiSiteData.logo.data.attributes.localFile.url}
              alt="Doganbros Logo"
              className="mr-3 image is-32x32"
            />
          </Link>
          <p className="has-text-white">
            &copy; Doganbros {new Date().getFullYear()}
          </p>
          <div className="footer-pad"/>
        </div>
      </div>
    </footer>
  )
}

export default Footer
