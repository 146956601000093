import { Link } from "gatsby"
import React from "react"

const Contact = () => (
  <section className="section" id="contact">
    <div className="container">
      <h3 className="title has-text-centered has-text-primary">
        We Are Ready to Work
      </h3>
      Regardless the size of your project, we are ready. We’re prepared to learn
      the technologies you wish. Tell us about your project, and let’s see what
      can we do for you.
      <div className="my-6 is-flex is-justify-content-center">
        <Link className="button is-primary is-large" to="/contact">
          Contact Us
        </Link>
      </div>
    </div>
  </section>
)

export default Contact
