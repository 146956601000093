import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"

const Cookie = () => {
  const [showBanner, setShowBanner] = useState(false)
  const location = useLocation()

  useEffect(() => {
    const getCookiesObject = () =>
      Object.fromEntries(
        document.cookie
          .split("; ")
          .map(v => v.split(/=(.*)/s).map(decodeURIComponent))
      )

    const cookies = getCookiesObject()
    setShowBanner(
      !cookies ||
        cookies["gatsby-gdpr-google-tagmanager"] === undefined
    )
    initializeAndTrack(location)
  }, [])

  const handleAccept = () => {
    document.cookie = " gatsby-gdpr-google-tagmanager=true"
    setShowBanner(false)
    initializeAndTrack(location)
  }
  const handleReject = () => {
    document.cookie = "gatsby-gdpr-google-tagmanager=false"
    setShowBanner(false)
  }

  return (
    showBanner && (
      <div id="cookie-banner" className="section py-5">
        <div className="container">
          <div className="title is-4">We value your privacy</div>
          <div>
            We use cookies to enhance your browsing experience and analyze our traffic. By clicking
            "Accept All", you consent to our use of cookies.
          </div>
          <div className="columns">
            <div className="column is-offset-6">
              <div className="level mt-5 is-mobile">
                <div className="level-item">
                  <button
                    onClick={handleAccept}
                    className="button is-primary is-rounded"
                  >
                    Accept All
                  </button>
                </div>
                <div className="level-item">
                  <button
                    onClick={handleReject}
                    className="button  is-rounded"
                  >
                    Reject All
                  </button>
                </div>
                <div className="level-left"></div>
                <div className="level-right"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default Cookie
