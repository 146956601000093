import React from "react"
import { Helmet } from "react-helmet"
import Contact from "./Contact"
import Footer from "./Footer"
import Header from "./Header"
import "../styles/scss/index.scss"
import Cookie from "./Cookie"

const Layout = ({ children, showContact = true }) => {
  return (
    <>
      <Helmet
        bodyAttributes={{
          class: "has-navbar-fixed-top",
        }}
      />
      <Header />
      {children}
      {showContact ? <Contact /> : null}
      <Cookie />
      <Footer />
    </>
  )
}

export default Layout
